import { useHistory } from 'react-router-dom';
import { DatabaseUseCase, SubscriptionType } from '@redislabsdev/cloud-ui-databases-types';
import { useFeatureFlags } from '../components/FeatureFlagsProvider/hooks/useFeatureFlags';
import { routes } from '../utils/constants/routes';
import useAccount from './useAccount';

interface NewSubscriptionPreSelectedValues {
  isFreePlan?: boolean;
  subscription?: SubscriptionType;
  dbUseCase?: DatabaseUseCase;
}
const useNavigateToNewSubscription = () => {
  const history = useHistory();
  const { account } = useAccount();
  const {
    flags: { packagingMvp }
  } = useFeatureFlags();

  const is3PIC = account?.is_sso_gcp;

  const navigateToNewSubscription = ({
    isFreePlan = false,
    subscription = is3PIC ? 'pro' : 'essential',
    dbUseCase = 'cache'
  }: NewSubscriptionPreSelectedValues = {}) => {
    if (packagingMvp) {
      const params = new URLSearchParams([['dbUseCase', dbUseCase]]);
      isFreePlan && params.append('isFreePlan', isFreePlan.toString());
      history.push({
        pathname: `${routes.createSubscription.root}/${subscription}`,
        search: params.toString()
      });
    } else {
      history.push(routes.createSubscription.essential);
    }
  };

  return { navigateToNewSubscription };
};

export default useNavigateToNewSubscription;
