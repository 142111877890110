import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { GoogleSigninIcon, SsoIcon, GithubIcon } from '@redislabsdev/redis-ui-icons/multicolor';
import * as S from './SocialLogin.style';
import SocialLoginButton from './SocialLoginButton/SocialLoginButton';
import { routes } from '../../../../utils/constants/routes';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import { handleSocialLogin, setLoginType } from '../../utils/LoginHelper';
import { parametersQueries } from '../../../../queryClient/parameters/parameters.queries';
import { useLoginContext } from '../../Login.context';

const SocialSignIn = ({ allowSSo }: { allowSSo?: boolean }) => {
  const { search } = useLocation();
  const history = useHistory();
  const { systemParams } = parametersQueries.useSystemParameters();
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;
  const showLoaderContext = useLoginContext();
  const searchParams = new URLSearchParams(search);
  const searchParamsAsObject = Object.fromEntries(searchParams);

  const thirdPartyItems = (service) => {
    showLoaderContext.setShowLoader(true);

    if (search) {
      window.sessionStorage.setItem('queryParams', search);
    }
    // used to save query params for when the request is finished and pass them to the login
    handleSocialLogin(
      service === 'google' ? systemParams.okta_google_idp_id : systemParams.okta_github_idp_id,
      oktaAuth,
      service // used to display the appropriate text inside the migration window
    );
  };

  if (searchParamsAsObject?.socialMethod) {
    thirdPartyItems(searchParamsAsObject.socialMethod);
  }

  if (searchParamsAsObject?.idpId) {
    setLoginType('sso');
    showLoaderContext.setShowLoader(true);
    oktaAuth.signInWithRedirect({ idp: searchParamsAsObject.idpId });
  }

  return (
    <S.SocialLoginContainer>
      <SocialLoginButton
        dataTestId="button--sign-in-via-google-account"
        onClick={() => thirdPartyItems('google')}
        name={t(keyPrefix.google)}
        icon={<GoogleSigninIcon customSize="4rem" />}
      />
      <SocialLoginButton
        dataTestId="button--sign-in-via-github-account"
        onClick={() => thirdPartyItems('github')}
        name={t(keyPrefix.github)}
        icon={<GithubIcon customSize="4rem" />}
      />
      {allowSSo && (
        <SocialLoginButton
          dataTestId="button--sign-in-via-saml"
          onClick={() => history.push(routes.saml)}
          name={t(keyPrefix.ssoBtn)}
          icon={<SsoIcon customSize="4rem" />}
        />
      )}
    </S.SocialLoginContainer>
  );
};

export default SocialSignIn;
