import { FC } from 'react';
import * as S from './FormInput.style';

interface IProps {
  textUnderInput: string;
}

const TextUnderInput: FC<IProps> = ({ textUnderInput }) => {
  const getTexts = () => {
    const [regularText, boldText] = textUnderInput.split(':', 2);

    return [`${regularText}: `, boldText];
  };

  const [regularText, boldText] = getTexts();

  return (
    <S.UnderInputText>
      {regularText}
      {boldText && <S.BoldText>{boldText}</S.BoldText>}
    </S.UnderInputText>
  );
};

export default TextUnderInput;
