import { FC } from 'react';
import { useField } from 'formik';
import { Input } from '@redislabsdev/redislabs-ui-components';
import TextUnderInput from './TextUnderInput';
import { FormInputContainer } from '../FormInputs.style';
import { FormInputProps } from './FormInput.types';
import InputLabelWithInfo from '../../InputLabelWithInfo/InputLabelWithInfo';

const FormInput: FC<FormInputProps> = ({
  testId,
  fieldName,
  textUnderInput,
  id,
  className,
  readMore,
  optional,
  ...rest
}) => {
  const { hoverableInfoIconProps, label, ...inputProps } = rest;
  const [field, meta] = useField(fieldName);
  const inputTestId = rest.readOnly ? `readonlyvalue--${testId}` : `textinput--${testId}`;

  return (
    <FormInputContainer className={className} data-testid={`form-container--${testId}`}>
      <InputLabelWithInfo
        hoverableInfoIconProps={hoverableInfoIconProps}
        label={label}
        testId={testId}
        readMore={readMore}
        htmlFor={id}
        optional={optional}
      />
      <Input
        id={id || testId}
        width="100%"
        error={meta.touched && meta.error}
        inputTestId={inputTestId}
        autoComplete="off"
        data-lpignore="true"
        {...field}
        value={field.value || ''}
        {...inputProps}
      />
      {textUnderInput && <TextUnderInput textUnderInput={textUnderInput} />}
    </FormInputContainer>
  );
};

export default FormInput;
