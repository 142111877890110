import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';

export const mwSubscriptionApi = createResourceApi(ApiPaths.MW_SUBSCRIPTION, (rest) => ({
  getOne: (id: number) =>
    rest.get<MwSubscriptionData>({
      path: `${id}`
    }),

  update: ({ id, maintenanceWindow }) =>
    rest.put<MwSubscriptionUpdateData>({
      path: `${id}`,
      data: maintenanceWindow
    }),

  getOptInToRedisUpgrade: (subscriptionId: number) =>
    rest.get<MwSubscriptionOptInToRedisUpgrade>({
      path: `${subscriptionId}/opt-in-to-redis-upgrade`
    }),

  updateOptInToRedisUpgrade: (subscriptionId: number) =>
    rest.post({
      path: `${subscriptionId}/opt-in-to-redis-upgrade`
    }),

  deleteOptInToRedisUpgrade: (subscriptionId: number) =>
    rest.delete({
      path: `${subscriptionId}/opt-in-to-redis-upgrade`
    }),

  skipMaintenance: (subscriptionId: number) =>
    rest.post<MwSkipMaintenanceData>({
      path: `${subscriptionId}/skip`
    }),

  initiateFailover: (subscriptionId: number, bdbId: number) =>
    rest.post<MwSkipMaintenanceData>({
      path: `failover/${subscriptionId}/${bdbId}`
    }),

  getFailover: (subscriptionId: number, bdbId: number) =>
    rest.get<MwFailoverDataResponse>({
      path: `failover/${subscriptionId}/${bdbId}`
    }),

  getValidateFailover: (subscriptionId: number, bdbId: number) =>
    rest.get<MwFailoverDataResponse>({
      path: `validateFailover/${subscriptionId}/${bdbId}`
    }),

  upgradeRedisVersion: (subscriptionId: number, databaseVersion: string) =>
    rest.post<MwRedisVersionUpgradeRequestResponse>({
      path: `${subscriptionId}/redis-version-upgrade`,
      data: { databaseVersion }
    }),

  getRedisVersionUpgradeRequest: (subscriptionId: number) =>
    rest.get<MwRedisVersionUpgradeRequest>({
      path: `${subscriptionId}/redis-version-upgrade`
    }),

  deleteRedisVersionUpgradeRequest: (subscriptionId: number, databaseVersion: string) =>
    rest.delete({
      path: `${subscriptionId}/redis-version-upgrade/${databaseVersion}`
    })
}));

export const mwAccountApi = createResourceApi(ApiPaths.MW_ACCOUNT, (rest) => ({
  getMaintenanceActivity: (accountId: number) =>
    rest.get<MwAccountMaintenanceActivityResponse>({
      path: `${accountId}/maintenance-activity`
    }),
  getAccountMetadata: (accountId: number) =>
    rest.get<MwAccountMetadata>({
      path: `${accountId}/metadata`
    })
}));
