import { FC } from 'react';
import { UseComboboxStateChange } from 'downshift';
import { Select } from '@redislabsdev/redis-ui-components';
import * as S from './InputFields.style';
import { setDataScrollAnchor } from '../../../utils/dataScrollAnchorUtils';

interface IProps {
  width: string;
  name?: string;
  label: string;
  error?: string;
  states: State[];
  selectedState: State;
  onStateSelect: (state: State) => void;
  info?: React.ReactNode;
  disabled?: boolean;
}

const StateSelectInput: FC<IProps> = ({
  name,
  label,
  width,
  states,
  onStateSelect,
  selectedState,
  error,
  info = null,
  disabled = false
}) => {
  const onOptionSelected = ({ selectedItem }: UseComboboxStateChange<State>) => {
    onStateSelect(selectedItem);
  };

  return (
    <S.InputFieldContainer width={width} {...(name && setDataScrollAnchor(name))}>
      <S.Label data-testid="label--province-name">
        {label}
        {info}
      </S.Label>
      <span>
        <Select
          disabled={disabled}
          error={error}
          value={selectedState?.name}
          onChange={(value) => {
            const selectedItem = states.find((state) => state.name === value);
            selectedItem && onOptionSelected({ selectedItem } as never);
          }}
          options={states.map((item: State) => ({
            item,
            value: item.name,
            label: item.name
          }))}
          data-testid="selectbox--province-name"
          placeholder=""
          searchable
          maxVisibleItems={6}
        />
      </span>
    </S.InputFieldContainer>
  );
};

export default StateSelectInput;
