import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CloudProviders } from '../components/CloudProvider/CloudProvider.types';
import { plansApi } from '../services/api/resources/plans/plans.resource';
import { isGcpAccountSelector } from '../store/account/account.selectors';
import useSmQuery from '../queryClient/useSmQuery';

export const REGIONS_QUERY_KEY = ['regions'];

const useRegions = () => {
  const isGcpAccount = useSelector(isGcpAccountSelector);

  const getRegionsFn = async () => {
    const [{ data: allRegions }, { data: rcpRegions }] = await Promise.all([
      plansApi.getAllCloudRegions(),
      plansApi.getAllCloudRegions('rcp')
    ]);

    return {
      allRegions,
      rcpRegions,
      regionsObject: keyBy(allRegions, 'id')
    };
  };

  const {
    data = {
      allRegions: [],
      rcpRegions: [],
      regionsObject: {}
    },
    isInitialLoading,
    isSuccess
  } = useSmQuery({
    permissionKey: 'plan_get_cloud_regions',
    queryKey: REGIONS_QUERY_KEY,
    queryFn: getRegionsFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const getRegionByRegionId = (regionId: number | string) =>
    data.regionsObject[Number(regionId)] || null;

  const getRegionByRegionName = useCallback(
    (regionName: string) => data.allRegions.find((region) => region.name === regionName),
    [data.allRegions]
  );

  const getRegionsByProvider = (cloudProvider: CloudProviders) => {
    return data.allRegions.filter((region) => region.cloud === cloudProvider);
  };

  const rcpRegions = useMemo(
    () =>
      isGcpAccount ? data.rcpRegions.filter((region) => region.cloud === 'gcp') : data.rcpRegions,
    [isGcpAccount, data.rcpRegions]
  );

  const getFilteredRcpRegions = useCallback(
    (cloudProvider: CloudProviders, supportMaz: boolean) => {
      return rcpRegions.filter(
        (region) => region.cloud === cloudProvider && (supportMaz ? region.support_maz : true)
      );
    },
    [rcpRegions]
  );

  return {
    ...data,
    rcpRegions,
    isInitialLoading,
    isSuccess,
    getRegionByRegionId,
    getRegionByRegionName,
    getRegionsByProvider,
    getFilteredRcpRegions
  };
};

export default useRegions;
