export function sortById<T extends { id?: number }>(arr: T[], isAsc = false) {
  const orderNum = isAsc ? -1 : 1;

  return arr.sort((a, b) => {
    if (a.id < b.id) return orderNum;
    if (b.id < a.id) return -orderNum;

    return 0;
  });
}
