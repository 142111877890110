import { FC } from 'react';
import { CountrySelect } from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu';
import * as S from './InputFields.style';

interface IProps {
  countries: Country[];
  onCountrySelect: (selectedCountry: Country) => void;
  selectedCountryCode: string;
  label: string;
  width: string;
  error?: string;
  disabled?: boolean;
  info?: React.ReactNode;
}

const CountrySelectInput: FC<IProps> = ({
  label,
  width,
  countries,
  selectedCountryCode,
  onCountrySelect,
  error,
  disabled,
  info = null
}) => {
  return (
    <S.InputFieldContainer width={width}>
      <S.Label data-testid="label--country">
        {label}
        {info}
      </S.Label>
      <CountrySelect
        error={error}
        disabled={disabled}
        countryList={countries}
        onCountrySelect={onCountrySelect}
        dataTestIdSuffix="country-name"
        selectedCountryCode={selectedCountryCode}
        errorTooltipProps={{ zIndex: 6 }}
      />
    </S.InputFieldContainer>
  );
};

export default CountrySelectInput;
