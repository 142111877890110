import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Input, Button } from '@redislabsdev/redis-ui-components';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { emailValidationSchema, setLoginType } from '../../utils/LoginHelper';
import { routes } from '../../../../utils/constants/routes';
import { samlMutations } from '../../../../queryClient/saml/saml.mutations';
import * as S from './SSoLoginForm.style';

const SsoLoginForm = () => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { mutate } = samlMutations.useGetIdp();
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
  const { t } = useTranslation();
  const emailValidation = emailValidationSchema(t);
  const keyPrefix = i18translation.LoginScreen;
  const onSubmit = ({ email }: { email: string }) => {
    mutate(email, {
      onSuccess: (idp) => {
        setLoginType('sso');
        oktaAuth.signInWithRedirect({ idp, loginHint: email });
      },
      onError: () => {
        setShowInvalidEmailError(true);
      }
    });
  };

  return (
    <S.SsoLoginFormContainer>
      <S.SignInTitle>{t(keyPrefix.singleSignOn)}</S.SignInTitle>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={emailValidation}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, dirty, isValid, errors }) => (
          <Form>
            <Input
              autoComplete="new-email"
              name="email"
              type="email"
              id="textfield--email"
              data-testid="textinput--email-address"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email as string}
              label={t(keyPrefix.email)}
            />
            {showInvalidEmailError && (
              <S.ErrorContainer>
                <S.ErrorBanner
                  show
                  variant="danger"
                  message={t(keyPrefix.ssoScreen.invalidEmail)}
                />
              </S.ErrorContainer>
            )}
            <S.FormButtonsContainer>
              <Button
                data-testid="button--cancel"
                variant="secondary-ghost"
                size="large"
                onClick={() => history.push(routes.login)}
              >
                {t(keyPrefix.ssoScreen.cancelBtn)}
              </Button>
              <Button
                data-testid="button--submit"
                type="submit"
                size="large"
                disabled={!dirty || !isValid}
              >
                {t(keyPrefix.login)}
              </Button>
            </S.FormButtonsContainer>
          </Form>
        )}
      </Formik>
    </S.SsoLoginFormContainer>
  );
};

export default SsoLoginForm;
