import styled, { css } from 'styled-components/macro';
import { FullHeightForm as FullHeightFormCommonStyle } from '../../../../../../utils/style';

export const Container = styled.div`
  scroll-behavior: smooth;
  display: flex;
  flex-flow: column;

  & div {
    p {
      display: block;
      padding-top: 1.8rem;
    }

    button {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
  }
`;

export const InlineInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 40rem;
`;

export const InlineTerms = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 0.6rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral700};

  & a.inline-anchor {
    color: ${({ theme }) => theme.semantic.color.text.primary600};
    padding-top: 0px;
    padding-left: 4px;
    padding-right: 4px;
    text-decoration: none;
    font-weight: bold;
  }
`;
export const FormSide = styled.div`
  background-color: ${({ theme }) => theme.semantic.color.background.neutral100};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const bigTitleRules = css`
  font-size: 2.8rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  font-weight: 600;
  padding-bottom: 4rem;
`;
export const FormTitle = styled.div<{ paddingBottom?: string }>`
  ${bigTitleRules};
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  word-break: normal;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};

  & hr {
    background-color: ${({ theme }) => theme.semantic.color.background.neutral500};
    width: 100%;
    height: 1px;
    margin-right: 3.5rem;

    &:last-of-type {
      margin-right: 0;
      margin-left: 3.5rem;
    }
  }
`;

export const FormWrapper = styled.div`
  justify-content: center;

  & form {
    display: flex;
    flex-direction: column;
  }

  & label {
    display: block;
    padding-top: 1.8rem;

    div {
      margin-top: 0.3rem;
    }
  }

  & a {
    padding-top: 1.2rem;
    text-decoration: underline;
    display: block;
  }

  & button {
    display: block;
    align-self: flex-end;
    margin-top: 4.5rem;
  }
`;

export const BottomTextWithLink = styled.div`
  & a {
    font-weight: bold;
  }
`;

export const FullHeightForm = styled(FullHeightFormCommonStyle)`
  height: auto;
`;
