import { ReactNode } from 'react';
import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import { Config } from 'config';

const Provider = await asyncWithLDProvider({
  clientSideID: Config.LAUNCH_DARKLY_CLIENT_ID,
  context: {
    key: Config.API_ENV,
    anonymous: true
  },
  options: {
    logger: basicLogger({ level: 'none' })
  }
});

interface Props {
  children?: ReactNode;
}

const FeatureFlagsProvider = ({ children }: Props) => {
  return <Provider>{children}</Provider>;
};

export default FeatureFlagsProvider;
