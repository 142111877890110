import { isArray, mergeWith, omit } from 'lodash';
import {
  DatabasesActionTypes,
  BdbState,
  DatabasesTypes,
  BdbSyncSourcesState,
  NewPriceData
} from './databases.types';
import {
  OldPricingPriceDetails,
  SET_OLD_PRICING_PRICE_DETAILS
} from '../../components/oldPricing/oldPricing.types';
import { objectToNormalized } from '../../utils/objectToNormalized';

const initialState = {
  dbs: { status: 'idle', data: {}, error: '' } as BdbState,
  bdbSyncSources: { status: 'idle', data: [], error: '' } as BdbSyncSourcesState,
  validateBdbSyncSourcesState: { status: 'idle', error: '' } as BaseState,
  backupDbState: { status: 'idle', error: '' } as BaseState,
  importDbState: { status: 'idle', error: '' } as BaseState,
  newPriceData: null as NewPriceData,
  newDbRcpPlan: {} as RcpPlanStatusResponse,
  oldPricingPriceDetails: null as OldPricingPriceDetails | null,
  shouldShowClusteringDialog: false,
  shouldShowBdbOptimizationDialog: false,
  showRedisStackCongratulations: false,
  databasesTablePageSize: 10,
  failoverData: {
    isModalOpen: false,
    subscriptionId: null,
    bdbId: null,
    crdbId: null
  }
};

type DatabasesState = typeof initialState;

const databasesReducer = (state = initialState, action: DatabasesTypes): DatabasesState => {
  switch (action.type) {
    case DatabasesActionTypes.START_DATABASES_POLLING:
    case DatabasesActionTypes.GET_DATABASES_REQUEST:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          status: state.dbs.status === 'resolved' ? 'resolved' : 'pending'
        }
      };

    case DatabasesActionTypes.GET_DATABASES_SUCCESS:
      return {
        ...state,
        dbs: { ...state.dbs, data: action.payload, status: 'resolved' }
      };

    case DatabasesActionTypes.GET_DATABASES_FAILURE:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          status: 'rejected',
          error: action.payload
        }
      };

    case DatabasesActionTypes.GET_BDB_SYNC_SOURCES_REQUEST:
      return {
        ...state,
        bdbSyncSources: {
          ...initialState.bdbSyncSources,
          status: 'pending'
        }
      };

    case DatabasesActionTypes.SET_DBS_STATUS:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          status: action.payload
        }
      };

    case DatabasesActionTypes.GET_BDB_SYNC_SOURCES_SUCCESS:
      return {
        ...state,
        bdbSyncSources: {
          ...state.bdbSyncSources,
          data: action.payload,
          status: 'resolved'
        }
      };

    case DatabasesActionTypes.GET_BDB_SYNC_SOURCES_FAILURE:
      return {
        ...state,
        bdbSyncSources: {
          ...state.bdbSyncSources,
          status: 'rejected',
          error: action.payload
        }
      };

    case DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_REQUEST:
      return {
        ...state,
        validateBdbSyncSourcesState: {
          status: 'pending',
          error: ''
        }
      };
    case DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_SUCCESS:
      return {
        ...state,
        validateBdbSyncSourcesState: {
          status: 'resolved',
          error: ''
        }
      };
    case DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_FAILURE:
      return {
        ...state,
        validateBdbSyncSourcesState: {
          status: 'rejected',
          error: action.payload
        }
      };
    case DatabasesActionTypes.RESET_DBD_VALIDATION_STATE:
      return {
        ...state,
        validateBdbSyncSourcesState: { status: 'idle', error: '' }
      };

    case DatabasesActionTypes.REFRESH_DB_SUCCESS:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          data: mergeWith({}, state.dbs.data, objectToNormalized(action.payload), (_a, b) =>
            isArray(b) ? b : undefined
          )
        }
      };

    case DatabasesActionTypes.BACKUP_DB_REQUEST:
      return {
        ...state,
        backupDbState: {
          status: 'pending',
          error: ''
        }
      };

    case DatabasesActionTypes.BACKUP_DB_SUCCESS:
      return {
        ...state,
        backupDbState: {
          status: 'resolved',
          error: ''
        }
      };

    case DatabasesActionTypes.BACKUP_DB_FAILURE:
      return {
        ...state,
        backupDbState: {
          status: 'rejected',
          error: ''
        }
      };

    case DatabasesActionTypes.IMPORT_DB_REQUEST:
      return {
        ...state,
        importDbState: {
          error: '',
          status: 'pending'
        }
      };

    case DatabasesActionTypes.IMPORT_DB_SUCCESS:
      return {
        ...state,
        importDbState: {
          error: '',
          status: 'resolved'
        }
      };

    case DatabasesActionTypes.IMPORT_DB_FAILURE:
      return {
        ...state,
        importDbState: {
          error: action.payload,
          status: 'rejected'
        }
      };

    case DatabasesActionTypes.RESET_IMPORT_DB_STATE:
      return {
        ...state,
        importDbState: initialState.importDbState
      };

    case DatabasesActionTypes.SET_NEW_PRICE_DATA:
      return {
        ...state,
        newPriceData: action.payload
      };

    case DatabasesActionTypes.SET_NEW_DB_RCP_PLAN:
      return {
        ...state,
        newDbRcpPlan: action.payload
      };

    case DatabasesActionTypes.SET_SHOULD_SHOW_CLUSTERING_DIALOG:
      return {
        ...state,
        shouldShowClusteringDialog: true
      };

    case DatabasesActionTypes.SET_SHOULD_SHOW_OPTIMIZATION_DIALOG:
      return {
        ...state,
        shouldShowBdbOptimizationDialog: true
      };

    case DatabasesActionTypes.USER_UPDATE_SUBS_PRICE_CONFIRMATION:
      return {
        ...state,
        newPriceData: initialState.newPriceData,
        oldPricingPriceDetails: initialState.oldPricingPriceDetails
      };

    case DatabasesActionTypes.USER_BDB_OPTIMIZATION_CONFIRMATION:
      return {
        ...state,
        shouldShowBdbOptimizationDialog: false
      };

    case DatabasesActionTypes.CANCEL_CREATE_UPDATE_DB:
      return {
        ...state,
        newPriceData: initialState.newPriceData,
        oldPricingPriceDetails: initialState.oldPricingPriceDetails,
        shouldShowClusteringDialog: false,
        shouldShowBdbOptimizationDialog: false,
        newDbRcpPlan: initialState.newDbRcpPlan
      };

    case DatabasesActionTypes.CREATE_DB_SUCCESS:
    case DatabasesActionTypes.UPDATE_DB_SUCCESS:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          data: mergeWith({}, state.dbs.data, objectToNormalized(action.payload), (_a, b) =>
            isArray(b) ? b : undefined
          )
        }
      };

    case DatabasesActionTypes.USER_CLUSTERING_CONFIRMATION:
      return {
        ...state,
        shouldShowClusteringDialog: false,
        newDbRcpPlan: initialState.newDbRcpPlan
      };

    case SET_OLD_PRICING_PRICE_DETAILS:
      return {
        ...state,
        oldPricingPriceDetails: action.payload
      };

    case DatabasesActionTypes.SET_DB_TABLE_PAGE_SIZE:
      return {
        ...state,
        databasesTablePageSize: action.payload
      };

    case DatabasesActionTypes.DELETE_DB_SUCCESS:
      return {
        ...state,
        dbs: {
          ...state.dbs,
          data: omit(state.dbs.data, action.payload.id)
        }
      };

    case DatabasesActionTypes.SET_SHOW_REDIS_STACK_CONGRATULATIONS:
      return {
        ...state,
        showRedisStackCongratulations: action.payload
      };

    case DatabasesActionTypes.SET_DB_FAILOVER_DATA:
      return {
        ...state,
        failoverData: action.payload
      };

    default:
      return state;
  }
};

export default databasesReducer;
