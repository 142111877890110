import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '@redislabsdev/redis-ui-components';
import { useLocation } from 'react-router-dom';
import * as S from './SetNewPasswordForm.style';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import { buildValidationSchema } from '../../utils/resetPassword';

const SetNewPasswordForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.resetPasswordScreen;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isInvitation = searchParams.has('invitation');

  return (
    <S.SetNewPasswordFormContainer>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          password: '',
          confirm_password: ''
        }}
        validationSchema={() => buildValidationSchema(t)}
        validateOnMount
      >
        {({ values, dirty, isSubmitting, isValid, handleChange, errors, touched, handleBlur }) => (
          <S.SetNewPasswordForm>
            <Input
              data-testid="textinput--password"
              type="password"
              label={t(keyPrefix.newPassword)}
              name="password"
              error={touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              data-testid="textinput--repeated-password"
              type="password"
              label={t(keyPrefix.reEnterPassword)}
              name="confirm_password"
              error={touched.confirm_password && errors.confirm_password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <S.SubmitButton
              data-testid="button--submit"
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
            >
              {t(isInvitation ? keyPrefix.updatePasswordBtn : keyPrefix.resetPasswordBtn)}
            </S.SubmitButton>
          </S.SetNewPasswordForm>
        )}
      </Formik>
    </S.SetNewPasswordFormContainer>
  );
};

export default SetNewPasswordForm;
