import { FC } from 'react';
import { NewTabIcon } from '@redislabsdev/redis-ui-icons';
import { InputLabelWithInfoProps } from './InputLabelWithInfo.types';
import * as S from './InputLabelWithInfo.style';

import HoverableInfoIcon from '../../HoverableInfoIcon/HoverableInfoIcon';
import { usePrefixedTranslation } from '../../../hooks';

const InputLabelWithInfo: FC<InputLabelWithInfoProps> = ({
  label,
  testId,
  hoverableInfoIconProps,
  readMore,
  className,
  optional,
  htmlFor
}) => {
  const { prefixedT } = usePrefixedTranslation('billingAndPayments.tabs.payments.addEditCCDialog');

  return (
    <S.Container {...{ className }}>
      <S.InputLabel data-testid={`readonlylabel--${testId}`} htmlFor={htmlFor || testId}>
        <span>
          {label}
          {optional && <S.Optional>{prefixedT('optional')}</S.Optional>}
        </span>
      </S.InputLabel>
      {hoverableInfoIconProps && <HoverableInfoIcon size="L" {...hoverableInfoIconProps} />}
      {readMore?.link && (
        <S.ReadMoreLink
          href={readMore.link}
          target="_blank"
          rel="noreferrer"
          data-testid={readMore?.testId}
          withMargin={readMore?.withMargin}
        >
          <NewTabIcon />
          {readMore?.text && readMore.text}
        </S.ReadMoreLink>
      )}
    </S.Container>
  );
};

export default InputLabelWithInfo;
