import { FC } from 'react';
import { Tooltip } from '@redislabsdev/redis-ui-components';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { HoverAbleInfoIconProps } from './HoverableInfoIcon.types';

const HoverableInfoIcon: FC<HoverAbleInfoIconProps> = ({
  tooltipContent,
  tooltipMaxWidth,
  size = 'XS',
  iconColor = 'primary300',
  textColor = 'neutral600',
  iconDataTestId
}) => {
  return (
    <Tooltip
      maxWidth={tooltipMaxWidth}
      content={tooltipContent}
      textColor={textColor}
      interactive={typeof tooltipContent !== 'string'}
    >
      <span {...(iconDataTestId && { 'data-testid': iconDataTestId })}>
        <InfoIcon size={size} color={iconColor} />
      </span>
    </Tooltip>
  );
};

export default HoverableInfoIcon;
