import styled from 'styled-components/macro';
import { FormInput as FormInputComponent } from '../../FormControls';

export const InputFieldContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
`;

export const FormInput = styled(FormInputComponent)<{ setWidth: string; bottomSpacing: boolean }>`
  width: ${({ setWidth }) => setWidth || '100%'};
  ${({ bottomSpacing }) => bottomSpacing && `margin-bottom: 1.6rem`};
`;
